<template>
  <div id="a-help-btn">
    <img :src="require('@/assets/icons/question-mark-circle.svg')" />
  </div>
</template>

<script>
export default {
  name: "HelpButton",
};
</script>

<style>
#a-help-btn :hover {
  cursor: help;
}
#a-help-btn img {
  height: 25px;
  object-fit: contain;
}
</style>
