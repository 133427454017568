import axios from "axios";

export default {
  namespaced: true,
  state() {
    return {
      user: null,
      axiosConfig: {
        url: "https://reinduction.gobiernocontigo.com/api/v1/",
        headers: {
          Authorization: null,
          refresh: null,
        },
      },
    };
  },
  mutations: {
    setUser(state, data) {
      state.user = data;
    },
    setHeaders(state, payload) {
      state.axiosConfig.headers.Authorization = `Bearer ${payload.access}`;
      state.axiosConfig.headers.refresh = payload.refresh;
    },
    setAccessHeader(state, header) {
      state.axiosConfig.headers.Authorization = `Bearer ${header.access}`;
    },
    clearUserInformation(state) {
      state.user = null;
      state.axiosConfig = {
        url: "https://reinduction.gobiernocontigo.com/api/v1/",
        headers: {
          Authorization: null,
          refresh: null,
        },
      };
    },
  },
  actions: {
    LOGIN({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${state.axiosConfig.url}rest-auth/login/`, payload)
          .then((response) => {
            commit("setHeaders", {
              access: response.data.access_token,
              refresh: response.data.refresh_token,
            });
            commit("setUser", response.data.user);

            if (response.data.user.userType.name == "Administrador") {
              resolve("AddTest");
            } else {
              resolve("Home");
            }
          })
          .catch((error) => {
            if (error.response) {
              let response = error.response.data;
              let errors = Object.keys(response);
              let message = "";
              errors.forEach((err) => {
                message += response[err];
              });
              reject(message);
            } else {
              reject(error.message);
            }
          });
      });
    },
    LOGOUT({ state, commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${state.axiosConfig.url}rest-auth/logout/`)
          .then(() => {
            commit("clearUserInformation");
            resolve();
          })
          .catch((error) => {
            if (error.response) {
              let response = error.response.data;
              let errors = Object.keys(response);
              let message = "";
              errors.forEach((err) => {
                message += response[err];
              });
              reject(message);
            } else {
              reject(error.message);
            }
          });
      });
    },
    async updateToken({ state, commit }) {
      await axios
        .post(`${state.axiosConfig.url}rest-auth/token/refresh/`, {
          refresh: state.axiosConfig.headers.refresh,
        })
        .then((response) => {
          commit("setAccessHeader", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
