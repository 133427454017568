<template>
  <Menubar>
    <template #start>
      <img
        :src="require('@/assets/images/logo/villavo-white.svg')"
        style="height: 40px"
      />
    </template>
    <template #end>
      <div id="a-rounded-icon-btn" @click="logout">
        <img :src="require('@/assets/icons/logout.svg')" style="height: 24px" />
      </div>
    </template>
  </Menubar>
</template>

<script>
export default {
  name: "EmployeeMenubar",
  methods: {
    logout() {
      this.$store
        .dispatch("auth/LOGOUT")
        .then(() => {
          this.$router.push({ name: "Login" });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.p-menubar {
  background: none !important;
  padding: 20px 40px;
  position: absolute;
  top: 0;
  height: 80px;
  min-width: 100%;
  z-index: 9999;
}
@media screen and (max-width: 960px) {
  .p-menubar {
    position: absolute !important;
  }
}
</style>

<style scoped>
#a-rounded-icon-btn {
  cursor: pointer;
}
</style>
