import axios from "axios";

export default {
  namespaced: true,
  actions: {
    GET_USER_TYPES({ dispatch, rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${rootState.auth.axiosConfig.url}usertype/`, {
            headers: {
              Authorization: rootState.auth.axiosConfig.headers.Authorization,
            },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            if (error.response) {
              let response = error.response.data;
              let errors = Object.keys(response);
              let message = "";
              let isTokenError = false;
              errors.forEach((err) => {
                message += response[err];
                if (err == "code") {
                  if (response[err] == "token_not_valid") {
                    isTokenError = true;
                    dispatch("auth/updateToken", "", { root: true });
                    reject("token");
                  }
                }
              });
              if (!isTokenError) {
                reject(message);
              }
            } else {
              reject(error.message);
            }
          });
      });
    },
    GET_USERS({ dispatch, rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${rootState.auth.axiosConfig.url}user/`, {
            headers: {
              Authorization: rootState.auth.axiosConfig.headers.Authorization,
            },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            if (error.response) {
              let response = error.response.data;
              let errors = Object.keys(response);
              let message = "";
              let isTokenError = false;
              errors.forEach((err) => {
                message += response[err];
                if (err == "code") {
                  if (response[err] == "token_not_valid") {
                    isTokenError = true;
                    dispatch("auth/updateToken", "", { root: true });
                    reject("token");
                  }
                }
              });
              if (!isTokenError) {
                reject(message);
              }
            } else {
              reject(error.message);
            }
          });
      });
    },
    CREATE_USER({ dispatch, rootState }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${rootState.auth.axiosConfig.url}user/`, data, {
            headers: {
              Authorization: rootState.auth.axiosConfig.headers.Authorization,
            },
          })
          .then(() => {
            resolve();
          })
          .catch((error) => {
            if (error.response) {
              let response = error.response.data;
              let errors = Object.keys(response);
              let message = "";
              let isTokenError = false;
              errors.forEach((err) => {
                message += response[err];
                if (err == "code") {
                  if (response[err] == "token_not_valid") {
                    isTokenError = true;
                    dispatch("auth/updateToken", "", { root: true });
                    reject("token");
                  }
                }
              });
              if (!isTokenError) {
                reject(message);
              }
            } else {
              reject(error.message);
            }
          });
      });
    },
    DELETE_USER({ dispatch, rootState }, data) {
      return new Promise((resolve, reject) => {
        data.is_active = false;
        data.userType = data.userType.id;
        axios
          .put(`${rootState.auth.axiosConfig.url}user/${data.id}/`, data, {
            headers: {
              Authorization: rootState.auth.axiosConfig.headers.Authorization,
            },
          })
          .then(() => {
            resolve();
          })
          .catch((error) => {
            if (error.response) {
              let response = error.response.data;
              let errors = Object.keys(response);
              let message = "";
              let isTokenError = false;
              errors.forEach((err) => {
                message += response[err];
                if (err == "code") {
                  if (response[err] == "token_not_valid") {
                    isTokenError = true;
                    dispatch("auth/updateToken", "", { root: true });
                    reject("token");
                  }
                }
              });
              if (!isTokenError) {
                reject(message);
              }
            } else {
              reject(error.message);
            }
          });
      });
    },
  },
};
