<template>
  <div class="a-small-title">
    <span class="a-blue-title">{{ blue }}</span>
    <span
      :class="
        needsMoreMargin ? 'a-white-title a-title-margin' : 'a-white-title'
      "
      >{{ white }}</span
    >
  </div>
</template>

<script>
export default {
  name: "FormattedTitle",
  props: {
    blue: String,
    white: String,
    needsMoreMargin: Boolean,
  },
};
</script>

<style scoped>
.a-small-title {
  height: 100px !important;
  width: 100%;
  position: absolute !important;
  top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.a-blue-title {
  color: var(--color-2);
  font-family: Quicksand;
  font-size: 40px;
  font-weight: bold;
  line-height: 0.92;
  letter-spacing: -2.65px;
}
.a-white-title {
  margin-top: -30px;
  font-family: Photoshoot;
  font-size: 53px;
  letter-spacing: -1.32px;
  color: #ffffff;
}
.a-title-margin {
  margin-top: -20px;
}
</style>
