import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      display: "Inicio",
      auth: true,
      title: "Inicio",
    },
  },
  {
    path: "/iniciar-sesion",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: {
      title: "Iniciar sesión",
    },
  },
  {
    path: "/certificado",
    name: "Certificates",
    component: () =>
      import(/* webpackChunkName: "Certificate" */ "../views/Certificate.vue"),
    meta: {
      display: "Inicio / Certificados",
      auth: true,
      title: "Certificados",
    },
  },
  {
    path: "/iniciar-examen",
    name: "InformationTestPage",
    component: () =>
      import(
        /* webpackChunkName: "InformationTestPage" */ "../views/InformationTestPage.vue"
      ),
    meta: {
      display: "Inicio / Examen",
      auth: true,
      title: "Iniciar examen",
    },
  },
  {
    path: "/examen/video",
    name: "VideoTestPage",
    component: () =>
      import(
        /* webpackChunkName: "VideoTestPage" */ "../views/VideoTestPage.vue"
      ),
    meta: {
      display: "Inicio / Pruebas",
      auth: true,
      title: "Pruebas",
    },
  },
  {
    path: "/examen/preguntas",
    name: "TestPage",
    component: () =>
      import(/* webpackChunkName: "TestPage" */ "../views/MainTestPage.vue"),
    meta: {
      display: "Inicio / Pruebas",
      auth: true,
      title: "Pruebas",
    },
  },
  {
    path: "/administrador/pruebas",
    name: "Tests",
    component: () =>
      import(/* webpackChunkName: "tests" */ "../views/secure/Tests/Tests.vue"),
    meta: {
      display: "Inicio / Pruebas",
      auth: true,
      title: "Pruebas",
    },
  },
  {
    path: "/administrador/pruebas/agregar",
    name: "AddTest",
    component: () =>
      import(
        /* webpackChunkName: "addTest" */ "../views/secure/Tests/AddTest.vue"
      ),
    meta: {
      display: "Inicio / Pruebas / Agregar",
      auth: true,
    },
  },
  {
    path: "/administrador/pruebas/agregar/preguntas",
    name: "AddQuestions",
    component: () =>
      import(
        /* webpackChunkName: "addQuestions" */ "../views/secure/Tests/AddQuestion.vue"
      ),
    meta: {
      display: "Inicio / Pruebas / Agregar / Preguntas",
      auth: true,
    },
  },
  {
    path: "/administrador/usuarios",
    name: "Users",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/secure/Users.vue"),
    meta: {
      display: "Inicio / Usuarios",
      auth: true,
      title: "Usuarios",
    },
  },
  {
    path: "/administrador/registros",
    name: "Registers",
    component: () =>
      import(
        /* webpackChunkName: "registers" */ "../views/secure/Registers.vue"
      ),
    meta: {
      display: "Inicio / Registros",
      auth: true,
      title: "Registros",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.auth);
  const isAuthenticated = store.state.auth.user;

  if (requiresAuth && isAuthenticated == null) {
    next("/iniciar-sesion");
  } else if (isAuthenticated != null && to.name == "Login") {
    next("/");
  } else {
    next();
  }
});

export default router;
