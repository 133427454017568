<template>
  <div class="a-options-card">
    <help-button />
    <div id="a-content">
      <slot></slot>
      <br />
      <span>{{ title }}</span>
    </div>
  </div>
</template>

<script>
import HelpButton from "../widgets/HelpButton.vue";
export default {
  props: {
    title: String,
  },
  components: {
    HelpButton,
  },
  name: "OptionsCard",
};
</script>

<style scoped>
.a-options-card {
  height: 220px;
  width: 200px;
  min-width: 200px;
  background: white;
  border-radius: 20px;
  margin: 0 20px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  color: black;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.02);
  cursor: pointer;
  position: relative;
}

.a-options-card #a-content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
</style>

<style>
.a-options-card #a-content img {
  height: 70px;
}
.a-options-card #a-content i {
  font-size: 70px;
  color: var(--color-2);
}
.a-options-card #a-content span {
  max-width: 200px;
  font-size: 18px;
  text-align: center;
  color: #333333;
}
.a-options-card #a-help-btn {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>
