import axios from "axios";
export default {
  namespaced: true,
  state() {
    return {
      answerAttempt: null,
      answers: [],
      storedAnswers: [],
      hasErrors: false,
    };
  },
  mutations: {
    storeAnswer(state, data) {
      state.answers.push(data);
    },
    resetAnswers(state) {
      state.storedAnswers = [];
      state.hasErrors = false;
      state.answers = [];
    },
    setAnswerAttempt(state, data) {
      state.answerAttempt = data;
    },
  },
  getters: {
    answeredQuestions(state) {
      if (state.answers.length == 0) {
        return 0;
      } else {
        let questions = [];
        state.answers.forEach((element) => {
          if (!questions.includes(element.question)) {
            questions.push(element.question);
          }
        });
        return questions;
      }
    },
  },
  actions: {
    UPDATE_LOCAL_ANSWER({ state }, data) {
      let found = state.answers.find(
        (answer) =>
          answer.question == data.question && answer.index == data.index
      );
      if (!found) {
        if (data.index == 0 || data.isOnlyAnswer) {
          let storedAnswers = state.answers.reduce(function (ind, el, i) {
            if (el.question == data.question) ind.push(i);
            return ind;
          }, []);
          storedAnswers.forEach((answer) => {
            state.answers.splice(answer, 1);
          });
        }
        state.answers.push(data);
      }
    },
    GET_ANSWERS_BY_USER({ dispatch, rootState, state }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${rootState.auth.axiosConfig.url}answertest/?active=true&user=${rootState.auth.user.pk}`,
            {
              headers: {
                Authorization: rootState.auth.axiosConfig.headers.Authorization,
              },
            }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            state.hasErrors = true;
            if (error.response) {
              let response = error.response.data;
              let errors = Object.keys(response);
              let message = "";
              let isTokenError = false;
              errors.forEach((err) => {
                message += response[err];
                if (err == "code") {
                  if (response[err] == "token_not_valid") {
                    isTokenError = true;
                    dispatch("auth/updateToken", "", { root: true });
                    reject("token");
                  }
                }
              });
              if (!isTokenError) {
                reject(message);
              }
            } else {
              reject(error.message);
            }
          });
      });
    },
    GET_ANSWER_ATTEMPT({ dispatch, rootState, state }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${rootState.auth.axiosConfig.url}answertest/?active=&user=${rootState.auth.user.pk}&test=${rootState.test.selectedTest.id}`,
            {
              headers: {
                Authorization: rootState.auth.axiosConfig.headers.Authorization,
              },
            }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            state.hasErrors = true;
            if (error.response) {
              let response = error.response.data;
              let errors = Object.keys(response);
              let message = "";
              let isTokenError = false;
              errors.forEach((err) => {
                message += response[err];
                if (err == "code") {
                  if (response[err] == "token_not_valid") {
                    isTokenError = true;
                    dispatch("auth/updateToken", "", { root: true });
                    reject("token");
                  }
                }
              });
              if (!isTokenError) {
                reject(message);
              }
            } else {
              reject(error.message);
            }
          });
      });
    },
    SAVE_ANSWER_ATTEMPT({ dispatch, rootState, state }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${rootState.auth.axiosConfig.url}answertest/`, data, {
            headers: {
              Authorization: rootState.auth.axiosConfig.headers.Authorization,
            },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            state.hasErrors = true;
            if (error.response) {
              let response = error.response.data;
              let errors = Object.keys(response);
              let message = "";
              let isTokenError = false;
              errors.forEach((err) => {
                message += response[err];
                if (err == "code") {
                  if (response[err] == "token_not_valid") {
                    isTokenError = true;
                    dispatch("auth/updateToken", "", { root: true });
                    reject("token");
                  }
                }
              });
              if (!isTokenError) {
                reject(message);
              }
            } else {
              reject(error.message);
            }
          });
      });
    },
    GET_ANSWERS_BY_ATTEMPT({ dispatch, rootState, state }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${rootState.auth.axiosConfig.url}answer/?answerTest=${data}`, {
            headers: {
              Authorization: rootState.auth.axiosConfig.headers.Authorization,
            },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            state.hasErrors = true;
            if (error.response) {
              let response = error.response.data;
              let errors = Object.keys(response);
              let message = "";
              let isTokenError = false;
              errors.forEach((err) => {
                message += response[err];
                if (err == "code") {
                  if (response[err] == "token_not_valid") {
                    isTokenError = true;
                    dispatch("auth/updateToken", "", { root: true });
                    reject("token");
                  }
                }
              });
              if (!isTokenError) {
                reject(message);
              }
            } else {
              reject(error.message);
            }
          });
      });
    },

    SAVE_ANSWER({ dispatch, rootState, state }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${rootState.auth.axiosConfig.url}answer/`, data, {
            headers: {
              Authorization: rootState.auth.axiosConfig.headers.Authorization,
            },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            state.hasErrors = true;
            if (error.response) {
              let response = error.response.data;
              let errors = Object.keys(response);
              let message = "";
              let isTokenError = false;
              errors.forEach((err) => {
                message += response[err];
                if (err == "code") {
                  if (response[err] == "token_not_valid") {
                    isTokenError = true;
                    dispatch("auth/updateToken", "", { root: true });
                    reject("token");
                  }
                }
              });
              if (!isTokenError) {
                reject(message);
              }
            } else {
              reject(error.message);
            }
          });
      });
    },
    SAVE_ANSWER_LIST({ state, dispatch }) {
      state.answers.forEach((answer) => {
        dispatch("SAVE_ANSWER", answer).then((response) => {
          state.storedAnswers.push(response);
        });
      });
    },
  },
};
