<template>
  <div id="a-carousel">
    <Menubar/>

    <transition-group tag="div" class="img-slider" name="fade">
      <div v-for="i in [slider.current]" :key="i" class="imgs-slide">
        <img :src="slider.images[slider.current]" />
      </div>
    </transition-group>

  

    <img
      v-if="isSliderActive"
      :src="require('@/assets/images/slider/figure_title.svg')"
      id="a-title-img"
    />

    <formatted-title
      :blue="blueTitle"
      :white="whiteTitle"
      :needsMoreMargin="true"
    />

    <div v-if="!isSliderActive" id="a-carousel-inactive">
      <slot></slot>
    </div>

    <div v-if="isSliderActive" id="a-carousel-active">
      <div class="a-carousel-arrows-left">
        <a @click="onPrev">
          <div id="a-rounded-icon-btn">
            <img :src="require('@/assets/icons/arrow_left.svg')" />
          </div>
        </a>
      </div>

      <div id="a-carousel-active-content" v-if="isSliderActive">
        <slot></slot>
      </div>

      <div class="a-carousel-arrows-right" style="z-index: 0;">
        <a @click="onNext">
          <div id="a-rounded-icon-btn">
            <img :src="require('@/assets/icons/arrow_right.svg')" />
          </div>
        </a>
      </div>
    </div>

    <div id="a-carousel-footer" v-if="isSliderActive">
      <span>
        <strong>
          Desarrollado por la Dirección de Sistemas Tecnológicas e Informáticos
          Secretaria de las TIC
        </strong>
        - Villavicencio Cambia Contigo
      </span>

      <div id="a-social-btn">
        <img
          id="a-social-message"
          :src="require('@/assets/icons/social_message.svg')"
        />
        <a @click="onPrev">
          <div id="a-rounded-social-btn">
            <img :src="require('@/assets/icons/facebook.svg')" />
          </div>
        </a>
        <a @click="onPrev">
          <div id="a-rounded-social-btn">
            <img :src="require('@/assets/icons/instagram.svg')" />
          </div>
        </a>
        <a @click="onPrev">
          <div id="a-rounded-social-btn">
            <img :src="require('@/assets/icons/twitter.svg')" />
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>

import FormattedTitle from "../widgets/FormattedTitle.vue";
import Menubar from "../navigation/Menubar.vue"
export default {
  name: "Carousel",
  components: {
    FormattedTitle,
    Menubar
  },
  props: {
    isSliderActive: Boolean,
    isInductionTitle: Boolean,
    isReinductionTitle: Boolean,
    isCertificatesTitle: Boolean,
    customTitlePt1: String,
    customTitlePt2: String,
  },
  computed: {
    blueTitle() {
      if (this.isInductionTitle) {
        return "Inducción de";
      } else if (this.isReinductionTitle) {
        return "Reinducción de";
      } else if (this.isCertificatesTitle) {
        return "Descargar";
      } else {
        return this.customTitlePt1;
      }
    },
    whiteTitle() {
      if (this.isInductionTitle || this.isReinductionTitle) {
        return "Funcionarios";
      } else if (this.isCertificatesTitle) {
        return "Certificado";
      } else {
        return this.customTitlePt2;
      }
    },
  },
  data() {
    return {
      slider: {
        images: [
          require("@/assets/images/slider/slider-1.png"),
          require("@/assets/images/slider/slider-2.jpeg"),
        ],
        current: 0,
      },
    };
  },
  methods: {
    onNext() {
      if (this.slider.current < this.slider.images.length - 1) {
        this.slider.current++;
      } else {
        this.slider.current = 0;
      }
    },
    onPrev() {
      if (this.slider.current > 1) {
        this.slider.current--;
      } else {
        this.slider.current = this.slider.images.length;
      }
    },
  },
};
</script>

<style scoped>
/* GENERAL STYLE */
#a-carousel {
  min-width: 100%;
  min-height: calc(100% - 80px);
  height: calc(100% - 80px);
}
#a-carousel img,
#a-img-carousel {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.8s ease;
  overflow: hidden;
  visibility: visible;
  opacity: 1;
  position: absolute;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  visibility: hidden;
}
.img-slider,
.imgs-slide {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
}
.img-slider::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: rgb(43, 90, 196);
  background: linear-gradient(
    90deg,
    rgba(43, 90, 196, 1) 13%,
    rgba(43, 90, 196, 0.6) 80%,
    rgba(43, 90, 196, 0.3) 100%
  );
}
.img-slider img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
/* END GENERAL STYLE */

/* ARROWS STYLE */
.a-carousel-arrows-left,
.a-carousel-arrows-right {
  position: absolute;
  width: 50px;
  height: 100%;
  z-index: 1000000;
  top: -80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.a-carousel-arrows-left a:hover,
.a-carousel-arrows-right a:hover {
  cursor: pointer;
}
.a-carousel-arrows-left {
  left: 0;
  margin-left: 40px;
}
.a-carousel-arrows-right {
  right: 0;
  margin-right: 40px;
}
.a-carousel-arrows-left #a-rounded-icon-btn,
.a-carousel-arrows-right #a-rounded-icon-btn {
  height: 30px;
  width: 30px;
}
.a-carousel-arrows-left #a-rounded-icon-btn img,
.a-carousel-arrows-right #a-rounded-icon-btn img {
  height: 15px !important;
  width: 15px !important;
}
/* END ARROWS STYLE */

/* ACTIVE CONTENT STYLE */
#a-carousel-active {
  position: absolute;
  height: calc(100% - 200px);
  width: 100%;
  top: 80px;
}
#a-carousel-active-content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  color: white;
  position: absolute;
  width: calc(100% - 200px);
  height: 100%;
  left: 100px;
  z-index: 1000000;
}
#a-title-img {
  height: 420px !important;
  max-width: 629px;
  object-fit: contain !important;
  position: absolute !important;
  top: 100px;
}
/* END ACTIVE CONTENT STYLE */

/* INACTIVE CONTENT STYLE */
#a-carousel-inactive {
  position: absolute;
  background: white;
  height: calc(100% - 200px);
  width: 100%;
  bottom: 0;
  border-radius: 20px 20px 0 0;
  padding: 0 50px 50px 50px;
}
/* END INACTIVE CONTENT STYLE */

/* FOOTER STYLE */
#a-carousel-footer {
  height: 80px;
  background: white;
}
#a-carousel-footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  padding: 0 20px;
}
#a-carousel-footer #a-social-btn {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
#a-carousel-footer span {
  font-size: 12px;
}
/* END FOOTER STYLE */
</style>
