<template>
  <div class="a-container" id="a-home-container">
    <Menubar />
    <Carousel :isSliderActive="true">
      <div id="a-row">
        <div id="a-text-content">
          <h2>
            Hola, <span>{{ user.first_name }} {{ user.last_name }}</span>
          </h2>
          <p>
            Es momento de afianzar tu conocimiento para llevar a cabo nuestro
            gran sueño,
            <strong>Villavicencio Cambia Contigo.</strong>
          </p>
        </div>
        <div id="a-cards-general">
          <OptionsCard
            :title="'Descargar certificado'"
            @click="onCardClick('Certificates', false)"
          >
            <img :src="require('@/assets/icons/certificate.svg')" />
          </OptionsCard>
          <span>Selecciona el proceso a realizar</span>
          <div id="a-cards">
            <options-card
              v-for="test in tests"
              :key="test.id"
              :title="test.name"
              @click="onCardClick('InformationTestPage', false, test)"
            >
              <i :class="test.icon" v-if="test.icon"></i>
            </options-card>
            <!-- <OptionsCard
              :title="'Inducción'"
              @click="
                onCardClick(
                  'http://moodleinduccion.villavicencio.gov.co/',
                  true
                )
              "
            >
              <img :src="require('@/assets/icons/teaching.svg')" />
            </OptionsCard>
            <OptionsCard
              :title="'Reinducción'"
              @click="onCardClick('InformationTestPage', false)"
            >
              <img :src="require('@/assets/icons/studying.svg')" />
            </OptionsCard> -->
          </div>
        </div>
      </div>
    </Carousel>
  </div>
</template>

<script>
import Menubar from "@/components/navigation/Menubar.vue";
import Carousel from "@/components/carousel/Carousel.vue";
import OptionsCard from "@/components/carousel/OptionsCard.vue";
import { mapMutations, mapState } from "vuex";
export default {
  name: "Home",
  components: {
    Menubar,
    Carousel,
    OptionsCard,
  },
  data() {
    return {
      tests: null,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  methods: {
    ...mapMutations("test", ["setSelectedTest"]),
    onCardClick(link, isExternal, test) {
      if (isExternal) {
        window.open(link, "_blank");
      } else {
        this.setSelectedTest(test);
        this.$router.push({ name: link });
      }
    },
    getTests() {
      this.$store
        .dispatch("test/GET_ACTIVE_TEST_LIST")
        .then((response) => {
          this.tests = response;
        })
        .catch((error) => {
          if (error == "token") {
            this.getTests();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error,
              life: 3000,
            });
          }
        });
    },
    changeScrollDirection() {
      var transformScroll = () => {
        if (!event.deltaY) {
          return;
        }
        event.currentTarget.scrollLeft += event.deltaY + event.deltaX;
        event.preventDefault();
      };
      document
        .getElementById("a-cards")
        .addEventListener("wheel", transformScroll);
    },
  },
  mounted() {
    this.getTests();
    this.changeScrollDirection();
  },
};
</script>

<style scoped>
/* GENERAL STYLE */
.a-container #a-home-container {
  flex-flow: column nowrap;
  justify-content: space-between;
}
#a-carousel-active-content #a-row {
  display: flex;
  flex-flow: nowrap;
  justify-content: space-between;
  margin-bottom: 50px;
  width: 100%;
}
/* END GENERAL STYLE */

/* TEXT CONTENT STYLE */
/* TITLE */
#a-text-content {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: start;
}
#a-text-content h2 {
  font-family: "Quicksand", sans-serif !important;
  font-size: 34px;
  margin: 0;
}
#a-text-content h2 span {
  color: var(--color-3);
}
/* PARAGRAPH */
#a-carousel-active-content #a-row p {
  max-width: 350px;
  font-size: 16px;
}
/* END TEXT CONTENT STYLE */

/* CARDS CONTAINER STYLE */
#a-cards-general {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
}
#a-cards-general span {
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 21px;
}
#a-cards {
  width: 100%;
  overflow-x: scroll;
  display: flex;
}
::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(165deg, var(--color-2) 18%, var(--color-3) 77%);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(270deg, var(--color-2) 18%, var(--color-3) 77%);
}
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 0px;
  box-shadow: inset 0px 0px 0px 0px #f0f0f0;
}
/* END CARDS CONTAINER STYLE */
</style>
