import axios from "axios";
export default {
  namespaced: true,
  state() {
    return {
      file: null,
      uploadedTest: null,
      selectedTest: null,
    };
  },
  mutations: {
    setFile(state, data) {
      state.file = data;
    },
    setTest(state, data) {
      state.uploadedTest = data;
    },
    setSelectedTest(state, data) {
      state.selectedTest = data;
    },
    resetTest(state) {
      state.uploadedTest = null;
      state.file = null;
    },
  },
  actions: {
    GET_TEST_LIST({ dispatch, rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${rootState.auth.axiosConfig.url}test/`, {
            headers: {
              Authorization: rootState.auth.axiosConfig.headers.Authorization,
            },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            if (error.response) {
              let response = error.response.data;
              let errors = Object.keys(response);
              let message = "";
              let isTokenError = false;
              errors.forEach((err) => {
                message += response[err];
                if (err == "code") {
                  if (response[err] == "token_not_valid") {
                    isTokenError = true;
                    dispatch("auth/updateToken", "", { root: true });
                    reject("token");
                  }
                }
              });
              if (!isTokenError) {
                reject(message);
              }
            } else {
              reject(error.message);
            }
          });
      });
    },
    GET_ACTIVE_TEST_LIST({ dispatch, rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${rootState.auth.axiosConfig.url}test/?active=true`, {
            headers: {
              Authorization: rootState.auth.axiosConfig.headers.Authorization,
            },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            if (error.response) {
              let response = error.response.data;
              let errors = Object.keys(response);
              let message = "";
              let isTokenError = false;
              errors.forEach((err) => {
                message += response[err];
                if (err == "code") {
                  if (response[err] == "token_not_valid") {
                    isTokenError = true;
                    dispatch("auth/updateToken", "", { root: true });
                    reject("token");
                  }
                }
              });
              if (!isTokenError) {
                reject(message);
              }
            } else {
              reject(error.message);
            }
          });
      });
    },
    SAVE_TEST({ commit, dispatch, rootState }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${rootState.auth.axiosConfig.url}test/`, data, {
            headers: {
              Authorization: rootState.auth.axiosConfig.headers.Authorization,
            },
          })
          .then((response) => {
            commit("setTest", response.data);
            resolve();
          })
          .catch((error) => {
            if (error.response) {
              let response = error.response.data;
              let errors = Object.keys(response);
              let message = "";
              let isTokenError = false;
              errors.forEach((err) => {
                message += response[err];
                if (err == "code") {
                  if (response[err] == "token_not_valid") {
                    isTokenError = true;
                    dispatch("auth/updateToken", "", { root: true });
                    reject("token");
                  }
                }
              });
              if (!isTokenError) {
                reject(message);
              }
            } else {
              reject(error.message);
            }
          });
      });
    },
    UPDATE_TEST({ commit, dispatch, rootState }, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${rootState.auth.axiosConfig.url}test/${data.get("id")}/`,
            data,
            {
              headers: {
                Authorization: rootState.auth.axiosConfig.headers.Authorization,
              },
            }
          )
          .then((response) => {
            commit("setFile", null);
            commit("setTest", response.data);
            resolve();
          })
          .catch((error) => {
            if (error.response) {
              let response = error.response.data;
              let errors = Object.keys(response);
              let message = "";
              let isTokenError = false;
              errors.forEach((err) => {
                message += response[err];
                if (err == "code") {
                  if (response[err] == "token_not_valid") {
                    isTokenError = true;
                    dispatch("auth/updateToken", "", { root: true });
                    reject("token");
                  }
                }
              });
              if (!isTokenError) {
                reject(message);
              }
            } else {
              reject(error.message);
            }
          });
      });
    },
    async SAVE_QUESTION({ state, rootState, dispatch }, question) {
      return new Promise((resolve, reject) => {
        question.test = state.uploadedTest.id;
        question.questionType = question.questionType.id;
        axios
          .post(`${rootState.auth.axiosConfig.url}question-option/`, question, {
            headers: {
              Authorization: rootState.auth.axiosConfig.headers.Authorization,
            },
          })
          .then(() => resolve())
          .catch((error) => {
            if (error.response) {
              let response = error.response.data;
              let errors = Object.keys(response);
              let message = "";
              let isTokenError = false;
              errors.forEach((err) => {
                message += response[err];
                if (err == "code") {
                  if (response[err] == "token_not_valid") {
                    isTokenError = true;
                    dispatch("auth/updateToken", "", { root: true });
                    reject("token");
                  }
                }
              });
              if (!isTokenError) {
                reject(message);
              }
            } else {
              reject(error.message);
            }
          });
      });
    },
    GET_QUESTION_LIST({ state, dispatch, rootState }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            // `${rootState.auth.axiosConfig.url}question/?active=true&test=${state.selectedTest.id}`,
            `${rootState.auth.axiosConfig.url}question/?active=true&test=${data.id}`,
            {
              headers: {
                Authorization: rootState.auth.axiosConfig.headers.Authorization,
              },
            }
          )
          .then((response) => {
            if (state.selectedTest != null) {
              state.selectedTest.questionList = response.data;
            } else {
              state.selectedTest = { questionList: response.data };
            }
            resolve();
          })
          .catch((error) => {
            if (error.response) {
              let response = error.response.data;
              let errors = Object.keys(response);
              let message = "";
              let isTokenError = false;
              errors.forEach((err) => {
                message += response[err];
                if (err == "code") {
                  if (response[err] == "token_not_valid") {
                    isTokenError = true;
                    dispatch("auth/updateToken", "", { root: true });
                    reject("token");
                  }
                }
              });
              if (!isTokenError) {
                reject(message);
              }
            } else {
              reject(error.message);
            }
          });
      });
    },
  },
};
