import axios from "axios";

export default {
  namespaced: true,
  state() {
    return {
      questionTypes: null,
    };
  },
  mutations: {
    setQuestionTypes(state, data) {
      state.questionTypes = data;
    },
  },
  actions: {
    GET_QUESTIONS_TYPES({ commit, dispatch, rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${rootState.auth.axiosConfig.url}questiontype/?active=true`, {
            headers: {
              Authorization: rootState.auth.axiosConfig.headers.Authorization,
            },
          })
          .then((response) => {
            commit("setQuestionTypes", response.data);
            resolve();
          })
          .catch((error) => {
            if (error.response) {
              let response = error.response.data;
              let errors = Object.keys(response);
              let message = "";
              let isTokenError = false;
              errors.forEach((err) => {
                message += response[err];
                if (err == "code") {
                  if (response[err] == "token_not_valid") {
                    isTokenError = true;
                    dispatch("auth/updateToken", "", { root: true });
                    reject("token");
                  }
                }
              });
              if (!isTokenError) {
                reject(message);
              }
            } else {
              reject(error.message);
            }
          });
      });
    },
  },
};
