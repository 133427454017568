import { createStore } from "vuex";
import auth from "@/store/auth";
import test from "@/store/test";
import miscellaneous from "@/store/miscellaneous";
import createPersistedState from "vuex-persistedstate";
import users from "@/store/users";
import answers from "@/store/answer";

export default createStore({
  plugins: [
    createPersistedState({
      paths: ["auth", "answers"],
      storage: window.sessionStorage,
    }),
  ],
  modules: {
    auth: auth,
    miscellaneous: miscellaneous,
    test: test,
    users: users,
    answers: answers,
  },
});
