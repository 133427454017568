<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "Inducción & Reinducción";
      },
    },
  },
};
</script>

<style>
html,
body,
#app {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
  max-height: 100%;
  min-height: 100%;
  height: 100%;
  width: 100%;
  margin: 0;
  min-width: 320px;
  overflow-x: hidden;
  position: relative;
  background: var(--color-1);
  color: var(--text-color);
}
</style>
