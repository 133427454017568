import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Axios from "axios";
import store from "./store";

require("./assets/styles/colors.css");
require("./assets/styles/buttons.css");
require("./assets/styles/inputs.css");
require("./assets/styles/containers.css");
require("./assets/styles/dialogs.css");
require("./assets/styles/table.css");

const app = createApp(App).use(router);
app.use(store);

app.config.globalProperties.axios = Axios;
app.config.globalProperties.http =
  "https://reinduction.gobiernocontigo.com/api/v1/";

import PrimeVue from "primevue/config";
import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import ToastService from "primevue/toastservice";
app.use(PrimeVue);
app.use(ToastService);

import InputText from "primevue/inputtext";
app.component("InputText", InputText);
import InputNumber from "primevue/inputnumber";
app.component("InputNumber", InputNumber);
import Button from "primevue/button";
app.component("Button", Button);
import Menubar from "primevue/menubar";
app.component("Menubar", Menubar);
import OverlayPanel from "primevue/overlaypanel";
app.component("OverlayPanel", OverlayPanel);
import Dialog from "primevue/dialog";
app.component("Dialog", Dialog);
import Sidebar from "primevue/sidebar";
app.component("Sidebar", Sidebar);
import DataTable from "primevue/datatable";
app.component("DataTable", DataTable);
import Column from "primevue/column";
app.component("Column", Column);
import Message from "primevue/message";
app.component("Message", Message);
import Toast from "primevue/toast";
app.component("Toast", Toast);
import Dropdown from "primevue/dropdown";
app.component("Dropdown", Dropdown);
import MultiSelect from "primevue/multiselect";
app.component("MultiSelect", MultiSelect);
import FileUpload from "primevue/fileupload";
app.component("FileUpload", FileUpload);
import ConfirmationService from "primevue/confirmationservice";
import ConfirmDialog from "primevue/confirmdialog";
app.use(ConfirmationService);
app.component("ConfirmDialog", ConfirmDialog);
import Badge from "primevue/badge";
app.component("Badge", Badge);
import Skeleton from "primevue/skeleton";
app.component("Skeleton", Skeleton);
import Card from "primevue/card";
app.component("Card", Card);
import Paginator from "primevue/paginator";
app.component("Paginator", Paginator);
import Tooltip from "primevue/tooltip";
app.directive("tooltip", Tooltip);
import Textarea from "primevue/textarea";
app.component("Textarea", Textarea);
import InputSwitch from "primevue/inputswitch";
app.component("InputSwitch", InputSwitch);
import ProgressBar from "primevue/progressbar";
app.component("ProgressBar", ProgressBar);
import InputMask from "primevue/inputmask";
app.component("InputMask", InputMask);
import Checkbox from "primevue/checkbox";
app.component("Checkbox", Checkbox);
import RadioButton from "primevue/radiobutton";
app.component("RadioButton", RadioButton);

app.mount("#app");
